$body: #FDFBF8;
$white: #fff;
$chapter_side: #F9F6F0;

$gray: #a1a1a1;
$gray_l1: #D3D5D9;
$gray_l2: #D9D9D3;
$gray_l3: #E6E8EB;
$gray_l4: #eae4cb;
$gray_l5: #7A797B;
$gray_d1: #414042;
$gray_d2: #505050;
$gray_d3: #79797a;

$green-50: #b9c4ab;
$green: #007c8e;
$green_l1: #609BA8;
$green_l2: #018397;

$light_green: #acbe3e;

// $gold: #BDA05A;
$gold: #BFA052;
$gold-50: #dfcfa9;
$gold-80: #ccb375;
$gold_l1: #ccad5f;

$red: #f0250e;
$red-80: #f3513e;
$brown: #5c3532;
$brown-80: #7c5e5b;
$beige: #d9d3c0;
$maroon: #ad0000;
$yellow: #f2b52b;

$blue-square: #BDD5D5;
$blue: #589597;
$blue-80: #79aaac;
$blue-50: #abcacb;
$blue_l1: #60a3b3;
$dark_blue: #457480;
$light_blue: #00a6be;

$circle-color: #F5F7FA;
$font: #414042;

$sdg3: #4E9E38;
$sdg5: #EF402E;
$sdg8: #A41C45;
$sdg12: #BA973D;
$sdg13: #587E4A;
$sdg17: #1A486A;

$brons-2020: #b3a780;
$brons-2020-80: #c2b999;
$green-2020: #728a56;
$green-2020-80: #8ea178;
$yellow-2020: #f7cf45;
$yellow-2020-80: #f9d96a;
$orange-2020: #ff6022;
$orange-2020-80: #ff804e;

$secondary-red: #da0000;
$secondary-green: #b0c358;
$secondary-red-80: #e13333;
$secondary-blue: #44a0b2;
$secondary-blue: rgb(87, 170, 186);
$secondary-blue-80: #69b3c1;
$secondary-gray: #7e838e;
$secondary-gray-80: #989ca5;

$policy-green: #ACBF3D;
$policy-blue: #6BADBC;


$light-background-2021: #fcfaf7;
$background-2021: #faf5ef;
$font-2021: #4b4544;
$light-font-2021: #d0bfb9;
$l2-font-2021: #e2e9e7;

$l3-green-2021: #cfdad6;
$l2-green-2021: rgb(172, 171, 137);
$light-green-2021: #aabdb7;
$green-2021: #8d9263;
$dark-green-2021: #467674;
$d2-green-2021: #498e93;
$l2-blue-2021: #95ada5;
$light-blue-2021: #9fb5ae;
$blue-2021: #728b8a;
$pastel-pink-2021: #bc8578;
$light-brown-2021: #b0948a;
$dark-brown-2021: #422622;
$brown-2021: #895d3f;
$yellow-2021: #dbab3e;
$peach-2021: #e97d3f;
$secondary-brown-2021: #bf8a3b;
$secondary-grey-2021: #5a4948;


$light-bg-2022: #f5f5f6;
$dark-bg-2022: $gray_d1;
$yellow-2022: #d9a734;
$green-2022: #7d8a84;
$light-green-2022: #9fb5ad;
$moss-green-2022: #97966c;
$brown-2022: $dark-brown-2021;
$gray-2022: $gray_d1;
$secondary-gray-2022: #707071;
$secondary-green-2022: #b3a780;
$secondary-yellow-2022: #cbba53;
$secondary-orange-2022: #e97d3f;
$secondary-brown-2022: #896050;
$secondary-red-2022: #c64d4d;


//Colors 2023
$light-bg-2023: #fbf7f4;
$font-2023: #3d3e3f;
$green-2023: #025000;
$green-2023-l1: #bccb72;
$green-2023-l2: #a7b779; //lightgreen
$green-2023-l3: #d3dbbc; //palegreen
$green-2023-l4: #e5e9d7;
$moss-green-2023: #728a56;
$green-2023-bright: #62af6e;
$yellow-2023: #f8aa04;
$burgundy-2023: #66322e;
$brown-2023: #7f4726;
$grey-2023: #3d3e3f;

$secondary-green-2023: #7fc28f; 
$secondary-green-2023-l1: #bfe1c7;
$secondary-green-2023-l2: #dee5b8;
$secondary-green-2023-d1: #90a992;
$secondary-green-2023-d2: #617162; //darkgreeen
$secondary-yellow-2023-d: #cba14d; //darkyellow
$secondary-yellow-2023-l: #fcd583; //lightyellow
$secondary-blue-2023: #658c8b;
$secondary-grey-2023: #26352d;

$font-2023: #3d3e3f;
$font-secondary-2023: $burgundy-2023;
$green-bg-2023: $green-2023-l3;
$link-hover: #6C4946;

$menu-bg-2023: $moss-green-2023;
$menu-active-2023: $green-2023-l2;

$submenu-bg-2023: $green-2023-l4;
$submenu-font-2023: $green-2023;
$submenu-underline-2023: $green-2023-l1;

$burgundy-2023d: rgb(102, 50, 46);


// Colors 2024

$grey-2024: #7A7A7A;
$light-2024: #F6F5EF;
$red-2024: #AD0000;
$red-l1-2024: #DA0000;
$red-l2-2024: #f0250e;

$red-hover-2024: #a00000;

// 75% opacity
$grey-2024-75: rgba(122, 122, 122, 0.75);
$red-2024-75: rgba(173, 0, 0, 0.75);
$red-l1-2024-75: rgba(218, 0, 0, 0.75);
$red-l2-2024-75: rgba(240, 37, 14, 0.75);   
// 50% opacity
$grey-2024-50: rgba(122, 122, 122, 0.5);
$red-2024-50: rgba(173, 0, 0, 0.5);
$red-l1-2024-50: rgba(218, 0, 0, 0.5);
$red-l2-2024-50: rgba(240, 37, 14, 0.5);
// 25% opacity
$grey-2024-25: rgba(122, 122, 122, 0.25);
$red-2024-25: rgba(173, 0, 0, 0.25);
$red-l1-2024-25: rgba(218, 0, 0, 0.25);
$red-l2-2024-25: rgba(240, 37, 14, 0.25);

// Secondary
$grey-d1-2024: #616161;
$grey-d2-2024: #4B4544;

$blue-2024: #6BADBD;
$blue-d1-2024: #728B8A;
$blue-l1-2024: #9FB5AE;

$green-2024: #ACBF3D;
$green-d1-2024: #8D9263;

$brown-2024: #5C3532;
$brown-l1-2024: #895D3F;
$brown-d1-2024: #422622;

$yellow-2024: #F2B52B;
$yellow-d1-2024: #DBAB3E;

$light-l1-2024: #EFECE0; // antique white
$light-l2-2024: #FAF5EF; // old lace
$light-l3-2024: #FCFAF7; // alabaster
$light-d-2024: #EAE8E1; // table odd row color

$rose-2024: #BC8578;