.policy-2020-block{
    margin-bottom: 30px;
    .policy-container{
        .policy-col{
            border-bottom: 1px solid;
            padding-top: 30px;
            padding-bottom: 20px;
            &.brown{
                border-color: $brown;
                .tags{
                    color: $brown;
                }
            }
            &.red{
                border-color: $red;
                .tags{
                    color: $red;
                }
            }
            &.green{
                border-color: $policy-green;
                .tags{
                    color: $policy-green;
                }
            }
            &.yellow{
                border-color: $yellow;
                .tags{
                    color: $yellow;
                }
            }
            &.blue{
                border-color: $policy-blue;
                .tags{
                    color: $policy-blue;
                }
            }
            &.red-2024 {
                border-color: $red-2024;
                .tags{
                    color: $red-2024;
                }
            }
            &.red-l1-2024 {
                border-color: $red-l1-2024;
                .tags{
                    color: $red-l1-2024;
                }
            }
            &.red-l2-2024 {
                border-color: $red-l2-2024;
                .tags{
                    color: $red-l2-2024;
                }
            }
            &.grey-2024 {
                border-color: $grey-2024;
                .tags{
                    color: $grey-2024;
                }
            }
            img{
                height: 100%;
                width: 100%;
            }
            .svg{
                min-width: 255px;
                margin-right: 20px;
                @include media-breakpoint-down(md){
                    min-width: 210px;
                    max-width: 210px;
                }
                @include media-breakpoint-down(sm){
                    min-width: 120px;
                    max-width: 120px;
                }
            }
            .policy-description{
                padding-top: 20px;
                p{
                    font-size: 14px;
                    line-height: 23px;
                    @media (max-width: 768px){
                        font-size: 11px;
                        line-height: 17px;
                    }
                }
                ul{
                    padding-left: 0;
                    li{
                        list-style-type: none;
                        position: relative;
                        padding-left: 20px;
                        font-size: 14px;
                        line-height: 23px;
                        &:before{
                            content: '';
                            position: absolute;
                            width: 6px;
                            height: 6px;
                            left: 0;
                            top: 10px;
                            background-color: $gray_d1;
                            border-radius: 100%;
                            @media (max-width: 768px){
                                left: 3px;
                                top: 6px;
                            }
                        }
                        @media (max-width: 768px){
                            font-size: 11px;
                            line-height: 17px;
                        }
                    }
                }
            }
            .tags{
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                margin-top: 18px;
                margin-bottom: 20px;
            }
        }
    } 
}