body {

    /////////////////////// Minor changes for 2024 report ///////////////////////
    &.year-2024 {
        color: $black !important;
        // Vantar nýjan font
        @include font-styles(18px, 28px, normal, normal, normal, 'aaux');
        font-family: 'aaux';



        header {
            .nav-container {
                background-color: $grey-2024;

                h3 {
                    font-size: 24px;

                    @include media-breakpoint-down(lg) {
                        font-size: 20px;
                    }
                }

                &.menu-open {
                    overflow-y: scroll;

                    nav.navbar {
                        height: unset !important
                    }
                }

                &.menu-open,
                &.down,
                &.up {
                    .pdf {
                        color: $white !important;
                    }

                    .navbar-toggler {
                        div {
                            background-color: $white !important;
                        }

                    }
                }

                &.down {
                    background-color: $grey-2024;
                }

                &.up {
                    top: -100px;
                }

                @include media-breakpoint-up(lg) {
                    background-color: $grey-2024;
                    flex-direction: column;
                }

                @include media-breakpoint-down(md) {
                    height: 80px;
                }

                .navbar {

                    &.menu-navbar {
                        height: unset !important;
                    }

                    &.header-navbar {
                        justify-content: space-between !important;
                    }

                    h3 {
                        color: $white;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }

                    .navbar-brand {
                        margin-right: unset;

                        img {
                            width: 240px;

                            @include media-breakpoint-down(md) {
                                width: 200px;
                            }
                        }
                    }

                    .pdf {
                        color: $white ;
                    }

                    .navbar-toggler {
                        div {
                            background-color: $white;
                        }

                    }
                }

                .top-menu-container-24 {
                    position: absolute;
                    background-color: $grey-2024;

                    // bottom: 0;
                    bottom: -25px;
                    left: 0;
                    border-top: 1px solid $light-2024;
                    border-bottom: 1px solid $light-2024;

                    .top-menu-row {
                        right: 0;
                        margin-top: 0 !important;
                        flex-wrap: nowrap;
                    }

                    .top-menu {
                        border-left: 1px solid $light-2024;
                        border-right: 1px solid $light-2024;
                        background-color: $grey-2024;
                        max-width: 245px;

                        &.show {
                            background-color: $red-2024;
                        }

                        .dropdown-toggle {
                            padding: 5px 8px;

                            &:hover {
                                text-decoration: none !important;
                            }

                            .menu-title {
                                color: $light-2024;
                                text-transform: uppercase;
                                font-size: calc(10px + (14 - 10) * ((100vw - 768px) / (1200 - 768)));

                                @media (min-width: 1200px) {
                                    font-size: 14px;
                                }

                                @media (max-width: 768px) {
                                    font-size: 10px;
                                }
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .top-ul-container {
                            ul {
                                padding: unset !important;
                                width: 100%;
                                border: 1px solid $grey-2024;

                                .child-item {
                                    padding: 5px 8px;
                                    background-color: $light-2024;
                                    border-bottom: 1px solid $grey-2024;
                                    font-size: calc(10px + (14 - 10) * ((100vw - 768px) / (1200 - 768)));

                                    @media (min-width: 1200px) {
                                        font-size: 14px;
                                    }

                                    @media (max-width: 768px) {
                                        font-size: 10px;
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    &:hover {
                                        background-color: $red-2024;

                                        a {
                                            border-bottom: none;

                                            span.title {
                                                color: white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .nav-container nav.navbar button.navbar-toggler:focus,
            :active {
                outline: none !important;
            }
        }


        &.page-arhomepage {
            @include media-breakpoint-down(md) {
                @media screen and (orientation:landscape) {
                    background-color: $light-2024 !important;
                    padding-top: 30px;
                }
            }

            .ar-home-page .title {
                color: $grey-2024;
                font-weight: 700;
                text-decoration: underline;
                text-transform: uppercase;


                font-size: calc(30px + (48 - 30) * ((100vw - 768px) / (1200 - 768)));

                @media (min-width: 1200px) {
                    font-size: 48px;
                    line-height: 1.2;
                }

                @media (max-width: 768px) {
                    font-size: 32px;
                    line-height: 1.2;
                }
            }

            .year-background {
                img {
                    position: fixed;
                    max-width: 65%;
                    width: 65%;
                    height: 1017px;
                    bottom: -321px;
                    right: -8%;
                    transition: all .3s ease-in-out;

                    @media (max-width: 1626px) {
                        right: -5%;
                    }

                    @media (max-width: 1200px) {
                        width: 58%;
                    }

                }
            }
        }


        &:not(.inngangur) {
            .chapter-container.first .chapter-content::after {
                background-color: $light-2024;
                height: 130vh;

                @include media-breakpoint-down(md) {
                    height: 60vh;
                    min-height: 100%;

                }

                @media (min-width: 1200px) {
                    @media screen and (orientation:portrait) {
                        width: 24vw;
                    }
                }
            }
            .chapter-container.first {
                .parent {
                    display: none;
                }
                .child {
                    margin-top: 40px;
                }
            }
        }

        &.inngangur:not(.arsreikningur):not(.sundurlidanir):not(.gri):not(.heimsmarkmid) {
            .chapter-container.first {
                @media (min-width: 992px) {
                    .right-col {
                        flex: 0 0 41.66667% !important;
                        max-width: 41.66667% !important;
                    }
                }

                @include media-breakpoint-down(md) {
                    .twocol_7_5 {
                        .left-col {
                            order: 2;
                        }

                        .right-col {
                            order: 1;
                        }
                    }
                }

                .article-2022-open {
                    height: 100%;

                    .container.two-column-block,
                    .container.paragraph-block {
                        height: 100% !important;
                    }
                }

                &.inngangur {
                    .chapter-content {

                        .container.two-column-block,
                        .container.paragraph-block {
                            height: 0;
                            overflow: hidden;
                        }

                        .two-column-block {
                            img {
                                width: 100%;
                            }

                            .focus-text-block {
                                .focus-text-container {
                                    .text {
                                        padding: 0 40px 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .background-container .background-image {
            background-color: $light-2024;

            &::after {
                background-color: $light-2024 !important;
            }

            svg.red {
                @include media-breakpoint-up(md) {
                    left: 26%;
                }

                path {
                    fill: $light-2024 !important;
                }
            }
        }

        .bottom-menu-container {
            .bottom-menu {
                background-color: $grey-2024;

                @include media-breakpoint-down(sm) {

                    ul {
                        margin-bottom: 30px;
                    }

                    li {
                        background-color: $grey-2024;
                    }
                }
            }

            .bottom-menu-btns {

                &.next-page-container {
                    background-color: $grey-d2-2024 !important;
                    transition: background-color .3s linear;
                    &:hover {
                        background-color: #403B3A !important;

                    }
                }

                &.prev-page-container {
                    background-color: $grey-d1-2024 !important;
                    transition: background-color .3s linear;
                    &:hover {
                        background-color: #585858 !important;
                    }
                }
            }
        }

        .ar-home-page {
            .title-container {
                padding-top: 100px;
            }

            @media (min-width: 992px) and (max-width: 1200px) {
                padding-top: 40px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 70px;
            }
        }

        // .side-menu-col {
        //     @include media-breakpoint-down(sm) {
        //         margin-top: 0;
        //     }

        //     .col-12 {
        //         @include media-breakpoint-up(xl) {
        //             flex: 0 0 140% !important;
        //             max-width: 140% !important;
        //         }
        //     }

        //     ul.side-menu {
        //         position: relative;
        //         z-index: 30 !important;

        //         @include media-breakpoint-down(sm) {
        //             min-width: calc(100vw - 30px);
        //         }

        //         li {
        //             color: $fo;
        //             font-weight: 700;

        //             &::after {
        //                 right: 50%;
        //                 background-image: url('../img/arrow.svg');
        //             }

        //             &.open {
        //                 // color: $green-2023;

        //                 &::after {
        //                     content: none;
        //                 }

        //                 .line {
        //                     background-color: $burgundy-2023;
        //                     border-color: $burgundy-2023;
        //                 }

        //                 ul.sub-menu {
        //                     background-color: $submenu-bg-2023;
        //                     right: 0;
        //                     top: 0;

        //                     li.chapters {
        //                         a {
        //                             color: $font-secondary-2023 !important;
        //                             text-decoration: none;

        //                             &:hover {
        //                                 border-bottom: 2px solid $submenu-underline-2023;
        //                             }
        //                         }
        //                     }
        //                 }
        //             }

        //             &.grey {
        //                 opacity: .7;

        //                 &::after {
        //                     content: none;
        //                 }
        //             }
        //         }
        //     }
        // }

        .chapter-container {
            background-color: $light-2024;

            .chart-block-container {
                .chart-element {
                    background-color: transparent !important;

                    .bottom-label-container {
                        flex-wrap: wrap;

                        .inner {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .standard-title-block {
            padding-top: 20px !important;
            margin-top: 0px !important;

            @include media-breakpoint-down(md) {
                padding-top: 0 !important;

            }
        }

        .prologue {
            .prologue-container {
                .read-more {
                    background-color: $red-2024;
                    border: none;
                    color: $white;
                    height: 40px;
                    text-transform: uppercase;
                    margin: 20px 0 0 0;
                    transition: background-color .3s linear;
                    &:hover {
                        background-color: $red-hover-2024;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &.article-open {
                    img {
                        margin-bottom: 20px;
                    }
                }

                .prologue-container {
                    .outer-container {
                        display: flex;

                        .col-12 {
                            flex: 0 0 60% !important;
                            max-width: 60% !important;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }

                        img {
                            // max-width: 40%;
                            min-height: 400px;
                        }
                    }
                }
            }
        }

        .link-block,
        .paragraph-block {
            a {
                color: $black;
                border-bottom: 1px solid $black;
                text-decoration: none;
                transition: all .3s;

                &:hover {
                    border-color: $red-2024;
                    color: $red-2024;
                }
            }

            svg {
                path {
                    &:first-child {
                        stroke: $black;
                    }

                    &:last-child {
                        fill: $black;
                    }
                }
            }
        }

        .image-link-block {
            .chapter {
                background-color: $red-2024;
                transition: background-color .3s linear;
                &:hover {
                    background-color: $red-hover-2024;
                }
            }
        }

        .subtitle-block {
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
        }

        .info-circle .number,
        .description {
            color: $light-2024 !important;
        }

        .info-circle .description {
            sub {
                font-size: 75% !important;
            }
        }


        &.page-arstandardindexpage,
        &.page-arstandardpage {

            @include media-breakpoint-down(lg) {
                &:not(.inngangur) {
                    .chapter-container.first {
                        background-color: $light-2024;
                    }
                }
            }

            .chapter-container.first {
                .circle-image.large-container {
                    background-color: $light-2024;

                    @media (min-width: 992px) {
                        height: 102vh;
                        margin-left: -20vw;
                        min-height: 1000px;
                        min-width: 80vw;
                    }

                    .chapter-image {
                        z-index: 0;

                        @media(max-width: 1220px) {
                            object-position: right;
                        }

                        @media(max-width: 992px) {
                            left: unset;
                            right: 0;
                        }
                    }

                    svg.circle-bg {
                        left: 0;
                        min-height: 102%;
                        z-index: 1;
                        // top: 0;

                        @media(max-width: 992px) {
                            display: none !important;
                        }

                        path {
                            fill: $light-2024;
                        }
                    }
                }
            }

            .chapter-container:not(.first),
            .chapter-container:not(:last-child) {
                padding-bottom: 60px !important;

                .anchor {
                    padding-bottom: 0 !important;
                }
            }

            .gri-body-container {
                .col-12 {
                    background-color: transparent;
                }

                .gri-table tbody {

                    tr.sub-heading-row {
                        background-color: $red-2024 !important;

                        td.gri-sub,
                        td.gri-sub-title,
                        td.hide {
                            color: $white !important;

                            td {
                                color: $white !important;
                            }
                        }

                    }

                    tr.gri-title-row {
                        background-color: transparent !important;
                    }

                    tr td {
                        padding-left: 10px;
                    }

                    tr:nth-child(odd) {
                        background-color: $light-d-2024;
                    }
                }
            }
        }

        .circle-image-block {
            .square-image {
                .info-circle {
                    &.right {
                        @include media-breakpoint-up(md) {
                            right: -25px !important;
                        }

                        @include media-breakpoint-down(md) {
                            right: 15px !important;
                        }
                    }
                }
            }

            @include media-breakpoint-between(sm, md) {
                margin: 0;

                .square-image {
                    .info-circle {
                        left: unset !important;
                    }
                }
            }

            .color-background {
                .circle-image {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: $red-l1-2024;
                        width: 100%;
                        max-width: 922px;
                        height: 100%;
                        top: 0;
                        transform: translate(40px, -40px);
                        z-index: -1;

                        @include media-breakpoint-down(md) {
                            content: none;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }

                &.left {
                    .circle-image {
                        &::before {
                            transform: translate(-40px, -40px);

                        }
                    }
                }

                &.make-circle {
                    .circle-image {
                        &::before {
                            border-radius: 50%;
                            transform: translate(21px, -8px);


                        }
                    }
                }
            }
        }

        .static-image-block {
            margin-bottom: 80px;
            margin-top: 0;

            .color-background {
                .static-img {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: $red-l2-2024-25;
                        width: 100%;
                        max-width: 922px;
                        height: 100%;
                        top: 0;
                        transform: translate(40px, -40px);
                        z-index: -1;

                        @include media-breakpoint-down(md) {
                            content: none;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }

                &.left {
                    .static-img {
                        &::before {
                            transform: translate(-40px, -40px);
                        }
                    }
                }
            }
        }

        .community-report-block {
            padding-bottom: 40px;

            .unit-container {
                .unit-logo {
                    display: flex;
                    align-items: center;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 8px;
                    }
                }
                .text a {
                    color: $black;
                    border-bottom: 1px solid $black;
                    transition: all .3s;

                    &:hover {
                        color: $red-2024;
                        border-bottom: 1px solid $red_2024;

                    }
                }
            }
        }

        .image-list-block {
            .img-title {
                color: $red-2024 !important;
            }
            .fw-light {
                font-weight: 300 !important;
            }
        }

        .vinbud-arsins-block {
            .title-container .title {
                background-color: $red-2024 !important;

                .rich-text p {
                    color: $light-2024 !important;
                }

            }

            .store-name {
                color: $light-2024 !important;
            }
        }

        .vinbud-arsins-block+.vinbud-arsins-block {
            margin-top: 0 !important;
        }

        .table-block .table-col {
            background-color: transparent !important;

            .title {
                color: $grey-2024 !important;
            }

            table {
                tr {
                    td {
                        &.grey-cell {
                            background-color: $light-2024 !important;
                        }
                    }
                }
            }
        }

        .box-counter-block .small-box,
        .box-counter-block .large-box {
            background-color: $white !important;

            .line-over {
                padding-top: 20px;
            }

            .line-under {
                padding-bottom: 20px;
            }
        }

        .two-column-block {
            padding-bottom: 0 !important;

            .right-col {
                flex-direction: column;
            }

            img {
                max-width: 100%;
            }

            .chart-block-container,
            .chart-element {
                background-color: transparent !important;
            }

            .poster-block {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    margin-top: 0;

                    img {
                        width: auto;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                padding: 20px 0 0 !important;
            }
        }

        &.vidskiptavinir,
        &.mannaudur,
        &.birgjar,
        &.eigandinn,
        &.samfelagid {
            &.page-arstandardindexpage {
                .chapter-container.first img.chapter-image {
                    @media (min-width:540px) and (max-width: 1200px) {
                        min-height: 70vh !important;
                    }

                    @media (max-width: 400px) {
                        min-height: 340px !important;
                        width: 120% !important;
                        // top: -50px;
                    }

                    @media (max-width: 380px) {
                        width: 100% !important;
                        bottom: -99vh !important;
                    }
                }
            }

            .second {
                padding-top: 60px;
            }
        }

        // .sidemenu-container {
        //     .current-page {
        //         font-weight: 700 !important;
        //     }

        //     ul {
        //         li {
        //             &::before {
        //                 background-color: $secondary-green-2023-l2;
        //             }

        //             &.active {
        //                 &::before {
        //                     background-color: $font-secondary-2023;
        //                 }
        //             }

        //             a {
        //                 font-weight: 700;
        //                 color: $font-secondary-2023;
        //                 text-decoration: none;
        //             }
        //         }
        //     }
        // }

        // .sidetext {
        //     color: $secondary-green-2023-l2 !important;
        //     font-family: 'Gotham-thin' !important;
        //     font-size: 90px !important;
        //     font-weight: 500 !important;
        //     letter-spacing: 8px;
        // }

        .chapter-container {
            .chapter-content-block {
                .text-col {
                    align-items: center;
                }

                .page-title {
                    font-weight: 700;
                    margin-top: 20px;
                    color: $grey-2024 !important;
                    font-size: 42px;
                    font-style: normal;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    line-height: normal;

                    font-size: calc(46px + (56 - 46) * ((100vw - 768px) / (1200 - 768)));

                    @media (min-width: 1200px) {
                        font-size: 56px;
                    }

                    @media (max-width: 768px) {
                        font-size: 46px;
                    }
                }
            }

            .titleblock {
                .parent {
                    color: $grey-2024;
                    display: inline-block;
                    padding-bottom: 0;
                    font-weight: 700;
                    font-size: 24px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    font-size: calc(18px + (24 - 18) * ((100vw - 768px) / (1200 - 768)));

                    @media (min-width: 1200px) {
                        font-size: 24px;
                    }

                    @media (max-width: 768px) {
                        font-size: 18px;
                    }
                }

                .child {
                    font-weight: 700;
                    margin-top: 20px;
                    color: $grey-2024 !important;
                    font-size: 42px;
                    font-style: normal;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    line-height: 1.2;

                    font-size: calc(32px + (28 - 32) * ((100vw - 768px) / (1200 - 768)));

                    @media (min-width: 1200px) {
                        font-size: 42px;
                    }

                    @media (max-width: 768px) {
                        font-size: 32px;
                    }
                }
            }
        }

        .sidemenu-container {
            .current-page {
                color: $red-2024 !important;
            }

            ul.sidemenu {
                li.active {
                    &::before {
                        background-color: $red-2024;
                    }

                    a {
                        color: $black;
                    }
                }
            }
        }

        &.inngangur {
            .sidemenu-container {
                .menu-items {
                    color: $grey-2023;

                    .sub-menu {
                        .chapters {
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .sdg-block {
            .svg-row {
                .svg-container {
                    display: none;

                }
            }
        }


        .focus-text-block-2023 {
            align-items: center;
            height: 100%;
            margin-bottom: 60px;

            .focus-text-container {
                .text {
                    color: $red_2024;
                    padding: 40px 30px;

                    p {
                        font-size: 32px;
                        font-weight: 500;
                        line-height: 40px;

                        @media (max-width: 992px) {
                            font-size: 28px;
                            line-height: 35px;
                        }

                        @media (max-width: 768px) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                    }

                    @media (max-width: 992px) {
                        max-width: 450px;
                        margin-top: 60px;
                    }

                    @media (max-width: 768px) {
                        margin-top: 40px;
                        max-width: 100%;
                    }
                }
            }
        }

        .table-container {
            table {
                thead {
                    background-color: $red_2024 !important;

                    tr th {
                        color: $white !important;
                    }

                    tbody {}
                }

            }
        }

        .gri-importance-block {
            padding: 20px !important;
            margin-bottom: 60px;
            margin-top: 40px;

            .table-container {
                background-color: transparent;
                padding: 0 15px 20px 15px;

                table {
                    thead {
                        color: $red_2024;


                        tr {
                            height: 60px;

                            th {
                                font-size: 18px;
                                line-height: 20px;
                                font-weight: 700;
                                border: none;

                                @media (max-width: 768px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    tbody {
                        font-size: 15px;

                        @media (max-width: 768px) {
                            font-size: 14px;
                        }

                        tr {
                            height: 46px;

                            &:nth-child(even) {
                                background-color: $light-d-2024;
                            }

                            @media (max-width: 768px) {
                                height: auto;
                                background-color: transparent !important;
                            }

                            td {
                                border-bottom: 1px solid $font;
                                border-top: 1px solid $font;

                                &.importance-gri-reference {
                                    a {
                                        color: $black;
                                        text-decoration: none;
                                        font-weight: 500;
                                        white-space: nowrap;
                                        transition: color 0.3s, border-color 0.3s;
                                        border-bottom: 1px solid $black;

                                        &:hover {
                                            color: $red-2024;
                                            border-color: $red-2024;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        @include media-breakpoint-down(lg) {
            .top-menu-container {
                .top-menu {
                    .menu-title {
                        color: $red_2024;
                    }

                    .line {
                        background-color: $red_2024;
                    }
                }

                .top-ul-container ul li a {
                    transition: border-color 0.3s;
                    border-bottom: 1.5px solid transparent;

                    &:hover {
                        border-color: $red_2024;
                    }

                }
            }
        }

        @include media-breakpoint-down(lg) {

            .navbar-collapse {
                width: 100vw !important;
                height: 100vh !important;
                position: relative !important;
                overflow: scroll !important;
            }
        }
    }
}